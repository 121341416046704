<template>
  <div id="home_wrapper" class="scroller">
    <div id="home" class="position-relative bg-yellow">
      <div class="header">
        <h1>Ode Toronto - Home</h1>
      </div>

      <Hero />

      <Story id="story" class="live-life-container" />

      <Rooms id="rooms" />

      <Roof id="roof" />

      <FullImg />

      <Neighbourhood id="neighbourhood" />

      <Art id="art" />

      <FAQ id="faq" />

      <Social id="social" />

      <AppFooter />
    </div>
  </div>
</template>

<script>
import jq from "jquery";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      scrollSmoother_home: null,
      fadeInUpElement_home: null,
    };
  },
  components: {
    Hero: () => import("@/components/Home/Hero"),
    Story: () => import("@/components/Home/Story"),
    Rooms: () => import("@/components/Home/Rooms"),
    Roof: () => import("@/components/Home/Roof"),
    Art: () => import("@/components/Home/Art"),
    FullImg: () => import("@/components/Home/FullImg"),
    Neighbourhood: () => import("@/components/Home/Neighbourhood"),
    FAQ: () => import("@/components/Home/FAQ"),
    Social: () => import("@/components/Home/Social"),
    AppFooter: () => import("@/components/Home/AppFooter"),
  },
  mounted() {
    this.loadMailchimpScript();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    ScrollTrigger.refresh();
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => this.$nuxt.$loading.finish(), 2500);
    });
    setTimeout(() => {
      this.initscrollSmoother_homePage();
      this.initImgElReveal_homePage();
    }, 500);
  },
  methods: {
    loadMailchimpScript() {
      const script = document.createElement("script");
      script.id = "mcjs";
      script.innerHTML = `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/c2dcb1a9b9f587e3b1adc84f2/587256eaa29fcdc8eeff86f35.js");`;
      document.body.appendChild(script);
    },
    initscrollSmoother_homePage() {
      this.scrollSmoother_home = ScrollSmoother.create({
        wrapper: "#home_wrapper",
        content: "#home",
        smooth: 3,
        effects: true,
      });
    },
    initImgElReveal_homePage() {
      let fadeInUpEl = document.querySelectorAll(".fade-in-up-element");

      fadeInUpEl.forEach((container) => {
        let fadeInUpElement_home = this.fadeInUpElement_home;
        fadeInUpElement_home = gsap.timeline({
          scrollTrigger: {
            trigger: container,
            start: "center bottom",
            stagger: 0.2,
          },
        });

        fadeInUpElement_home.set(container, { autoAlpha: 1, zIndex: 1 });
        fadeInUpElement_home.from(container, 1, {
          opacity: 0,
          y: 100,
          ease: "power2.out",
        });
      });
    },
  },
  beforeDestroy() {
    setTimeout(() => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    }, 500);
  },
};
</script>
